import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import helpersService from "../../services/helpers.service";
import configData from "../../config.json";
const IMAGE_URL = configData.REACT_APP_IMAGE_URL;
const APP_URL = configData.REACT_APP_URL;
const Header = () => {
    const [user, setUser] = useState(null)

    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        window.location.href = APP_URL+'/auth/login';
        // navigate('/auth/login');
    }
    const getUser = async () => {
        var guser = authService.getCurrentUser();
        setUser(guser);
    }
    useEffect(() => {
        getUser()
    }, []);
    return (
        <React.Fragment>
            <div className="headmain">
                <table width="100%">
                    <tbody>
                    <tr>
                        <td style={{width:"15%"}}>
                            <img src={IMAGE_URL+"logo.webp?v=0"} width="50px" alt="" />
                        </td>
                        <td style={{width:"50%"}}>
                            <span className="d-inline-block text-name" id="user_name">
                                <i className="fas fa-user" style={{color: "#ffda6a"}}></i> {user && user.name}
                            </span>
                            <br/>
                            <span className="d-inline-block" id="user_bank">
                                <img src={ user && user.data.bank.data.imageUrl} width="17px" alt=""/> {user && (user.bankAccountNo.length > 10 ? helpersService.formatNo(user.bankAccountNo,'###-#-#####-###') : helpersService.formatNo(user.bankAccountNo,'###-#-#####-#'))}
                            </span>
                        </td>
                        <td style={{width:"15%"}}>
                            <table align="right">
                                <tbody>
                                <tr>
                                    <td className="pr-3">
                                               
                                    </td>
                                    <td>
                                        <button className="logoutbtn" id="btn_logout" onClick={logout} ><i className="fal fa-power-off mr-0"></i></button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
export default Header;